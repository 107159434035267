<template>
  <div class="container">
    <van-list
      v-model="listLoading"
      class="list"
      :finished="listFinished"
      :finished-text="listFinishedText"
      @load="onListLoad"
    >
      <div
        v-for="item in list"
        :key="item.course_cate_id"
        class="list-item van-hairline--bottom"
        @click="onDetailClick(item.course_cate_id)"
      >
        <div class="list-item-hd">
          <van-image class="list-item-img" :src="item.category_cover" :alt="item.category_title" />
        </div>
        <div class="list-item-bd">
          <div class="list-item-title">{{ item.category_title }}</div>
          <!-- <div class="list-item-txt">{{item.category_title}}</div> -->
          <div class="list-item-desc">{{ item.category_desc }}</div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import PageMixin from '@/mixins/page'

export default {
  name: 'ClassCaregory',
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {

  },
  methods: {
    onListLoad() {
      this.$api
        .course_index({ 'per-page': this.pageSize, page: this.pageCurrent })
        .then(res => {
          this.listLoading = false
          this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
          this.listFinished = this.list.length >= res._meta.totalCount
          this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
          this.pageCurrent += 1
        })
    },
    onDetailClick(id) {
      this.$router.push({ path: 'class-list', query: { course_cate_id: id }})
    }
  }
}
</script>
<style lang="less" scoped>

.list-item {
  box-sizing: border-box;
  background: #fff;
  padding: 10px 15px;
  // margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  &-img {
    width: 168px;
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;
  }
  &-bd {
    flex: 1;
    height: 100%;
  }
  &-title {
    font-size: 14px;
    color: #333;
    margin-bottom: 2px;
  }
  &-txt {
    font-size: 14px;
    color: #333;
  }
  &-desc {
    font-size: 12px;
    color: #666;
  }
}
</style>

